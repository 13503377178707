import { HTMLProps } from 'react'
import styled from 'styled-components'
import { Link } from '../../text/Link'
import { GenericModal, GenericModalProps } from './GenericModal'

const HREF_COLES_CONTACT_US =
  'http://www.coles.com.au/customer-service/contact-us'

const HREF_COLES_PRIVACY_POLICY =
  'https://www.coles.com.au/important-information/privacy-policy'

const CollectionContentContainer = styled.div`
  font-family: 'Source Sans', sans-serif;
  padding: 0;
`

const CollectionParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
`

function ModalLink(props: HTMLProps<HTMLAnchorElement>) {
  return (
    <Link primary={true} target='_blank' href={props.href}>
      {props.children}
    </Link>
  )
}

export function CollectionNoticeModal(props: GenericModalProps) {
  return (
    <GenericModal {...props}>
      <CollectionContentContainer>
        <CollectionParagraph>
          We are collecting your personal information so you can use our online
          ordering service, and so we can understand and serve you better.
        </CollectionParagraph>
        <CollectionParagraph>
          We may engage a third-party provider to fulfill your order delivery.
          Our delivery partners may have their own information collection
          practices, including requiring you to provide personal information to
          verify your age and identity at the point of delivery. We will not
          otherwise disclose the personal information provided by you unless
          required or authorised by law or in accordance with our privacy
          policy.
        </CollectionParagraph>
        <CollectionParagraph>
          Without your personal information, we may not be able to provide you
          with our service or offer access to all available delivery timeframes
          and styles. For example, when you place an order, we need to collect
          and share your name, address, and contact details with our delivery
          partners.
        </CollectionParagraph>
        <CollectionParagraph>
          Our{' '}
          <ModalLink href={HREF_COLES_PRIVACY_POLICY}>privacy policy</ModalLink>{' '}
          explains more about the types of personal information we collect, how
          we collect, use and disclose it and how you can access and/or correct
          it, or make a privacy complaint.
        </CollectionParagraph>
        <CollectionParagraph>
          Questions or concerns? Contact Email (via webform):{' '}
          <ModalLink href={HREF_COLES_CONTACT_US}>
            Customer Care - Contact us | Coles
          </ModalLink>{' '}
          or 1800 061 562.
        </CollectionParagraph>
      </CollectionContentContainer>
    </GenericModal>
  )
}
